import { callFetch } from './serviceHelpers';

export const getSavedTenants = (accessToken, env) =>
  callFetch(
    'customizrUrl',
    'GET',
    `v1/resources/${env === 'int' ? 'int-' : ''}platform-selected-tenant/settings`,
    null,
    accessToken,
    env
  ).catch(err => {
    if (err.response && err.response.status === 404) {
      //not actually an issue, just means this user hasn't saved it yet
      return {};
    }
    // if customizr fails, there is no real harm in starting over and having the user re-select their tenant
    console.warn(err);
    return {};
  });

export const putSavedTenants = (accessToken, env, tenants) =>
  callFetch(
    'customizrUrl',
    'PUT',
    `v1/resources/${env === 'int' ? 'int-' : ''}platform-selected-tenant/settings`,
    JSON.stringify(tenants),
    accessToken,
    env
  ).catch(err => {
    // if customizr fails, there isn't much we can do other than log it.  They will not have their tenants persisted.
    console.warn(err);
  });

const ACCOUNTS_SERVICE_API_URL = 'https://accounts.cimpress.io/api/v1/accounts';
export const ACCOUNT_CLAIM = 'https://claims.cimpress.io/account';
export const ACCOUNTS_MANAGEMENT_DASHBOARD_URL = 'https://accounts.cimpress.io';
export const fetchAccountDetails = ({ account, accessToken }) => {
  return fetch(`${ACCOUNTS_SERVICE_API_URL}/${account}`, {
    method: 'GET',
    mode: 'cors',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    }),
  })
    .then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        return {};
      }
    })
    .catch(error => {
      console.error(error);
      return {};
    });
};

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import BrandingContext from './branding/BrandingContext';

const DefaultLogo = props => {
  let logoColor = props.placement === 'sideNav' ? '#FFFFFF' : '#3B414E';
  return (
    <svg width={props.width} viewBox="56 53 352 53">
      <desc>Created with Sketch.</desc>
      <defs />
      <g
        id="cimpress-open-logo"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(56.000000, 53.000000)">
        <g id="Group">
          <path
            d="M25.7,24.5 C25.7,24.5 23.6,26.6 25.7,28.8 L31.6,34.7 C31.6,34.7 33.7,36.8 35.9,34.7 L41.8,28.8 C41.8,28.8 43.9,26.7 41.8,24.5 L35.9,18.6 C35.9,18.6 33.8,16.5 31.6,18.6 L25.7,24.5 L25.7,24.5 Z"
            id="Shape"
            fill="#F0553C"
          />
          <path
            d="M23.5,35.7 L14.4,26.6 L23.4,17.6 C23.4,17.6 25.5,15.5 23.4,13.3 L20.2,10.1 C20.2,10.1 18.1,8 15.9,10.1 L1.6,24.5 C1.6,24.5 -0.5,26.6 1.6,28.8 L4.8,32 L4.8,32 L16,43.2 C16,43.2 18.1,45.3 20.3,43.2 L23.5,40 C23.5,40 25.6,37.8 23.5,35.7 L23.5,35.7 Z"
            id="Shape"
            fill={logoColor}
          />
          <path
            d="M66,24.5 L62.8,21.3 L43.4,1.9 C43.4,1.9 41.3,-0.2 39.1,1.9 L35.9,5.1 C35.9,5.1 33.8,7.2 35.9,9.4 L53.1,26.6 L35.9,43.8 C35.9,43.8 33.8,45.9 35.9,48.1 L39.1,51.3 C39.1,51.3 41.2,53.4 43.4,51.3 L66,28.8 C66,28.8 68.1,26.6 66,24.5 L66,24.5 Z"
            id="Shape"
            fill={logoColor}
          />
          <g transform="translate(75.000000, 9.000000)" id="Shape" fill={logoColor}>
            <path d="M0.4,17.7 L0.4,17.6 C0.4,8.4 7.4,0.8 17.3,0.8 C22.4,0.8 25.8,2.2 28.6,4.7 C29.1,5.2 29.4,5.7 29.4,6.3 C29.4,6.8 29.2,7.4 28.7,7.9 L26.2,10.6 C25.7,11.1 25.1,11.4 24.6,11.4 C24.1,11.4 23.6,11.2 23,10.7 C21.4,9.4 19.7,8.7 17.3,8.7 C12.7,8.7 9.5,12.7 9.5,17.5 L9.5,17.6 C9.5,22.5 12.7,26.5 17.7,26.5 C20,26.5 21.7,25.8 23.4,24.5 C23.9,24.1 24.5,23.8 25,23.8 C25.5,23.8 26.1,24 26.6,24.6 L28.8,26.8 C29.4,27.4 29.6,28 29.6,28.5 C29.6,28.9 29.5,29.3 29.1,29.6 C26.2,32.4 22.8,34.2 17.1,34.2 C7.5,34.4 0.4,26.9 0.4,17.7 L0.4,17.7 Z" />
            <path d="M31.5,3.7 C31.5,2.2 32.3,1.4 33.8,1.4 L38.4,1.4 C39.9,1.4 40.7,2.2 40.7,3.7 L40.7,31.4 C40.7,32.9 39.9,33.7 38.4,33.7 L33.8,33.7 C32.3,33.7 31.5,32.9 31.5,31.4 L31.5,3.7 L31.5,3.7 Z" />
            <path d="M44,3.7 C44,2.2 44.8,1.4 46.3,1.4 L50.9,1.4 C52.4,1.4 53.2,2.2 53.2,3.7 L53.2,6 C55.3,3.3 58.1,0.8 62.7,0.8 C66.9,0.8 70.1,2.7 71.8,5.9 C74.6,2.6 78,0.8 82.4,0.8 C89.2,0.8 93.3,4.9 93.3,12.7 L93.3,31.4 C93.3,32.9 92.5,33.7 91,33.7 L86.4,33.7 C84.9,33.7 84.1,32.9 84.1,31.4 L84.1,15.7 C84.1,11.4 82.2,9.1 78.7,9.1 C75.3,9.1 73.2,11.3 73.2,15.7 L73.2,31.4 C73.2,32.9 72.4,33.7 70.9,33.7 L66.3,33.7 C64.8,33.7 64,32.9 64,31.4 L64,15.7 C64,11.4 62.1,9.1 58.6,9.1 C55.2,9.1 53.1,11.3 53.1,15.7 L53.1,31.4 C53.1,32.9 52.3,33.7 50.8,33.7 L46.2,33.7 C44.7,33.7 43.9,32.9 43.9,31.4 L43.9,3.7 L44,3.7 Z" />
            <path d="M96.5,3.7 C96.5,2.2 97.3,1.4 98.8,1.4 L103.4,1.4 C104.9,1.4 105.7,2.2 105.7,3.7 L105.7,6 C107.9,3 111,0.8 115.8,0.8 C123.3,0.8 130.5,6.7 130.5,17.5 L130.5,17.6 C130.5,28.4 123.5,34.3 115.8,34.3 C110.9,34.3 107.9,32.1 105.7,29.5 L105.7,41 C105.7,42.5 104.9,43.3 103.4,43.3 L98.8,43.3 C97.3,43.3 96.5,42.5 96.5,41 L96.5,3.7 L96.5,3.7 Z M121.3,17.6 L121.3,17.5 C121.3,12.1 117.7,8.6 113.4,8.6 C109.1,8.6 105.6,12.2 105.6,17.5 L105.6,17.6 C105.6,23 109.2,26.5 113.4,26.5 C117.7,26.5 121.3,23 121.3,17.6 L121.3,17.6 Z" />
            <path d="M132.2,3.7 C132.2,2.2 132.9,1.4 134.5,1.4 L139,1.4 C140.5,1.4 141.3,2.2 141.3,3.7 L141.3,6.1 C142.3,3.7 145.2,1.5 149.2,0.9 C150.7,0.7 151.5,1.6 151.5,3.1 L151.5,7.6 C151.5,9.9 150.7,10.2 149,10.4 C143.2,11.3 141.2,16.3 141.2,21.8 L141.2,31.4 C141.2,32.9 140.4,33.7 138.9,33.7 L134.4,33.7 C132.8,33.7 132.1,32.9 132.1,31.4 L132.1,3.7 L132.2,3.7 Z" />
            <path d="M150.7,17.7 L150.7,17.6 C150.7,8.4 157.3,0.8 166.7,0.8 C177.5,0.8 182.4,9.2 182.4,18.3 L182.4,18.5 C182.4,19.9 181.5,20.7 180.1,20.7 L159.9,20.7 C160.8,24.9 163.7,27 167.8,27 C170.2,27 172.1,26.4 174,25.1 C174.6,24.7 175.1,24.4 175.7,24.4 C176.2,24.4 176.7,24.6 177.2,25.1 L179.1,26.8 C179.8,27.3 180.1,27.9 180.1,28.5 C180.1,28.9 179.9,29.3 179.5,29.7 C176.6,32.6 172.7,34.3 167.6,34.3 C157.8,34.4 150.7,27.6 150.7,17.7 L150.7,17.7 Z M173.4,15 C172.9,10.9 170.5,8.1 166.6,8.1 C162.8,8.1 160.3,10.8 159.6,15 L173.4,15 L173.4,15 Z" />
            <path d="M183.2,30.7 C182.5,30.2 182.1,29.6 182.1,28.9 C182.1,28.5 182.3,28 182.6,27.5 L184,25.3 C184.5,24.5 185.1,24.2 185.8,24.2 C186.2,24.2 186.7,24.4 187.2,24.7 C190.1,26.4 193,27.3 195.4,27.3 C198,27.3 199.3,26.3 199.3,24.9 L199.3,24.8 C199.3,23.1 196.8,22.3 193.8,21.4 C193.4,21.3 193,21.2 192.6,21 C188.1,19.7 183,17.6 183,11.3 L183,11.2 C183,4.6 188.3,1 194.8,1 C198.2,1 201.8,2 204.9,3.6 C205.7,4 206.2,4.6 206.2,5.3 C206.2,5.7 206,6.2 205.8,6.7 L204.5,9 C204,9.8 203.4,10.3 202.7,10.3 C202.3,10.3 201.9,10.2 201.4,9.9 C198.9,8.7 196.6,8 194.7,8 C192.5,8 191.3,9 191.3,10.2 L191.3,10.3 C191.3,12.1 194.4,12.9 197.9,14.2 C202.4,15.7 207.7,17.9 207.7,23.8 L207.7,23.9 C207.7,31.1 202.3,34.3 195.4,34.3 C191.3,34.3 187.1,33.1 183.2,30.7 L183.2,30.7 Z" />
            <path d="M209,30.7 C208.3,30.2 207.9,29.6 207.9,28.9 C207.9,28.5 208.1,28 208.4,27.5 L209.8,25.3 C210.3,24.5 210.9,24.2 211.6,24.2 C212,24.2 212.5,24.4 213,24.7 C215.9,26.4 218.8,27.3 221.2,27.3 C223.8,27.3 225.1,26.3 225.1,24.9 L225.1,24.8 C225.1,23.1 222.6,22.3 219.6,21.4 C219.2,21.3 218.8,21.2 218.4,21 C213.9,19.7 208.8,17.6 208.8,11.3 L208.8,11.2 C208.8,4.6 214.1,1 220.6,1 C224,1 227.6,2 230.7,3.6 C231.5,4 232,4.6 232,5.3 C232,5.7 231.8,6.2 231.6,6.7 L230.3,9 C229.8,9.8 229.2,10.3 228.5,10.3 C228.1,10.3 227.7,10.2 227.2,9.9 C224.7,8.7 222.4,8 220.5,8 C218.3,8 217.1,9 217.1,10.2 L217.1,10.3 C217.1,12.1 220.2,12.9 223.7,14.2 C228.2,15.7 233.5,17.9 233.5,23.8 L233.5,23.9 C233.5,31.1 228.1,34.3 221.2,34.3 C217.1,34.3 212.9,33.1 209,30.7 L209,30.7 Z" />
          </g>
          <g transform="translate(308.000000, 10.000000)" id="Shape" fill={logoColor}>
            <polygon points="2.1 1.6 0.4 1.6 0.4 0.4 5.1 0.4 5.1 1.6 3.4 1.6 3.4 6.2 2.1 6.2" />
            <polygon points="6.1 0.4 7.5 0.4 9 2.8 10.5 0.4 11.9 0.4 11.9 6.1 10.7 6.1 10.7 2.4 9.1 4.8 9.1 4.8 7.5 2.4 7.5 6.1 6.3 6.1 6.3 0.4" />
          </g>
        </g>
      </g>
    </svg>
  );
};

const Logo = props => {
  const branding = useContext(BrandingContext);

  if (!branding.shouldUse) {
    const { placement, width } = props;
    return <DefaultLogo placement={placement} width={width} />;
  }

  // We do want branding but we do NOT have branding... :)
  if (!branding.data || !branding.data.header || !branding.data.header.logo) {
    return null;
  }

  const logoInfo =
    props.placement === 'sideNav'
      ? (branding.data.header.sideNav || {}).logo || branding.data.header.logo
      : branding.data.header.logo;

  if (!logoInfo) {
    return null;
  }

  return <img id="header-logo" alt={logoInfo.alt} width={logoInfo.width || props.width} src={logoInfo.dataUri} />;
};

Logo.propTypes = {
  width: PropTypes.string,
  placement: PropTypes.oneOf('default', 'sideNav'),
};

Logo.defaultProps = {
  width: '472px',
  placement: 'default',
};

export default Logo;

import { callFetch } from './serviceHelpers';

export const getMerchants = (accessToken, env) =>
  callFetch('merchantConfigUrl', 'GET', 'v0/merchants?verbose=false', null, accessToken, env).then(merchants =>
    merchants.map(({ merchantId, displayName, claimCategories, _links }) => ({
      merchantId,
      displayName: displayName || merchantId,
      claimCategories,
      _links,
    }))
  );

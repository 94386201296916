const prd = {
  merchantConfigUrl: 'https://merchant-config.commerce.cimpress.io',
  fulfillerIdentityUrl: 'https://fulfilleridentity.trdlnk.cimpress.io',
  customizrUrl: 'https://customizr.at.cimpress.io',
};

const int = {
  merchantConfigUrl: 'https://int-merchant-config.commerce.cimpress.io',
  fulfillerIdentityUrl: 'https://fulfilleridentity.trdlnk.cimpress.io',
  customizrUrl: 'https://customizr.at.cimpress.io',
};

const local = {
  merchantConfigUrl: 'http://localhost:9101',
  fulfillerIdentityUrl: 'http://localhost:9101',
  customizrUrl: 'http://localhost:9101',
};

export default {
  prd,
  int,
  local,
};

import { getMcpSettings, setPreferredMcpSettings } from 'cimpress-customizr';
import { getFirstSupportedLanguage } from './utils/languages';
import { languageCodes } from './constants/languages';

const settingsCache = {};

const EMPTY_LIST = [];

export const getSettings = (authToken, supportedLanguages = languageCodes) => {
  if (settingsCache.global) {
    return Promise.resolve(settingsCache);
  }
  return getMcpSettings(authToken)
    .then(globalSettings => {
      // NOTE: globalSettings.language is the array of preferred languages
      const language = getFirstSupportedLanguage(globalSettings.language || EMPTY_LIST, supportedLanguages);
      settingsCache.global = {
        language,
        regionalSettings: globalSettings.regionalSettings,
        timezone: globalSettings.timezone,
      };
      return settingsCache;
    })
    .catch(err => {
      console.warn(err);
      return settingsCache;
    });
};

export const putGlobalSettings = (authToken, language, regionalSettings, timezone) => {
  return setPreferredMcpSettings(authToken, language, regionalSettings, timezone).then(newData => {
    settingsCache.global = {
      // NOTE: newData.language is the array of preferred languages
      language: newData.language[0],
      regionalSettings: newData.regionalSettings,
      timezone: newData.timezone,
    };
  });
};

export const putAppSettings = (authToken, appSettings) => {
  settingsCache.app = appSettings;
};

import React from 'react';

import PropTypes from 'prop-types';
import Modal from '@cimpress/react-components/lib/Modal';
import Loading from '@cimpress/react-components/lib/shapes/Spinner';
import ManyTenantsSelect from './ManyTenantsSelect';
import SimpleSelect from './SimpleSelect';

import './SelectorModal.css';

/**
 * Maximum number of tenants user has access to that will still show the simplified selector
 */
const MAX_TENANTS_SIMPLE_SELECTOR = 6;

class SelectorDelegator extends React.Component {
  static propTypes = {
    authToken: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    selectedFulfiller: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedMerchant: PropTypes.string,
    resourceType: PropTypes.string,
    merchants: PropTypes.arrayOf(
      PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        merchantId: PropTypes.string.isRequired,
      })
    ).isRequired,
    fulfillers: PropTypes.arrayOf(
      PropTypes.shape({
        fulfillerId: PropTypes.string.isRequired,
        internalFulfillerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        archived: PropTypes.bool,
      })
    ).isRequired,
  };

  render() {
    let { authToken, onChange, resourceType, selectedFulfiller, selectedMerchant, merchants, fulfillers } = this.props;

    let tenantCountOverTreshold = merchants.length + fulfillers.length > MAX_TENANTS_SIMPLE_SELECTOR;
    let selectorComponent = tenantCountOverTreshold ? (
      <ManyTenantsSelect
        {...{
          authToken,
          selectedMerchant,
          resourceType,
          onChange,
          merchants,
          fulfillers,
        }}
      />
    ) : (
      <SimpleSelect
        {...{
          selectedMerchant,
          selectedFulfiller,
          resourceType,
          onChange,
          merchants,
          fulfillers,
        }}
      />
    );

    return selectorComponent;
  }
}

const ModalFooter = ({ onSave, onClose, mustSelectTenant, canSave }) => (
  <div className="pull-right">
    <button className="btn btn-default" disabled={mustSelectTenant} onClick={onClose}>
      Cancel
    </button>
    <button className="btn btn-primary" disabled={!canSave} onClick={onSave}>
      Save
    </button>
  </div>
);

ModalFooter.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  mustSelectTenant: PropTypes.bool.isRequired,
  canSave: PropTypes.bool.isRequired,
};

const SelectorModal = ({ mustSelectTenant, selectedTenant, show, loading, onClose, title, helpText, ...props }) => (
  <Modal
    className="modal-overflow"
    show={show || mustSelectTenant}
    title={title}
    onRequestHide={onClose}
    closeButton={!mustSelectTenant}
    footer={<ModalFooter {...{ mustSelectTenant, onClose, loading }} {...props} />}>
    {helpText && <p style={{ marginBottom: '40px' }}>{helpText}</p>}
    {loading ? <Loading className="text-center" /> : <SelectorDelegator selectedTenant={selectedTenant} {...props} />}
  </Modal>
);

SelectorModal.propTypes = {
  authToken: PropTypes.string.isRequired,
  merchants: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      merchantId: PropTypes.string.isRequired,
    })
  ).isRequired,
  fulfillers: PropTypes.arrayOf(
    PropTypes.shape({
      fulfillerId: PropTypes.string.isRequired,
      internalFulfillerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      archived: PropTypes.bool,
    })
  ).isRequired,
  resourceType: PropTypes.string,
  mustSelectTenant: PropTypes.bool.isRequired,
  selectedMerchant: PropTypes.string,
  selectedFulfiller: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  show: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  canSave: PropTypes.bool.isRequired,
};

export default SelectorModal;

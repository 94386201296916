import ServiceHttpResponseError from './serviceHttpResponseError';
import endpoints from './endpoints.js';

export function checkResponse(serviceEndpoint) {
  return res => {
    if (res.ok) {
      return res.json();
    } else {
      throw new ServiceHttpResponseError(`Error calling ${serviceEndpoint} endpoint`, res);
    }
  };
}

export function callFetch(endpointKey, method, route, body, accessToken, env) {
  const endpointUrl = (endpoints[env] || endpoints['prd'])[endpointKey];

  const headers = new Headers({
    Accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
  });

  method = method ? method.toUpperCase() : 'GET';
  if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
    headers.append('Content-Type', 'application/json');
  }

  const options = {
    method,
    mode: 'cors',
    headers,
    body,
  };

  const fullUrl = route ? `${endpointUrl}/${route}` : endpointUrl;
  return fetch(fullUrl, options).then(checkResponse(`${method} ${fullUrl}`));
}

import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@cimpress/react-components/lib/RadioGroup';
import Radio from '@cimpress/react-components/lib/Radio';
import MerchantSelect from './MerchantSelect';
import { FulfillerSelect } from 'react-cimpress-fulfillers-components';

class ManyTenantsSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceType: props.resourceType || 'merchant',
      selectedFulfiller: null,
    };

    this.onSelectType = this.onSelectType.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  static propTypes = {
    authToken: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    selectedMerchant: PropTypes.string,
    merchants: PropTypes.arrayOf(
      PropTypes.shape({
        merchantId: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
      })
    ).isRequired,
    fulfillers: PropTypes.arrayOf(
      PropTypes.shape({
        fulfillerId: PropTypes.string.isRequired,
        internalFulfillerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        archived: PropTypes.bool,
      })
    ).isRequired,
  };

  componentDidMount() {
    this.trigger();
  }

  onChange(tenant) {
    if (this.props.onChange) {
      this.props.onChange({
        type: this.state.resourceType,
        tenant,
      });
    }
  }

  trigger() {
    let { merchants, selectedMerchant } = this.props;
    let { resourceType, selectedFulfiller } = this.state;

    let actualMerchant = merchants.find(m => m.merchantId === selectedMerchant);
    if (resourceType === 'merchant' && actualMerchant) {
      this.onChange(actualMerchant);
    } else if (resourceType === 'fulfiller' && this.state.selectedFulfiller) {
      this.onChange(selectedFulfiller);
    }
  }

  onSelectType(e, resourceType) {
    this.setState({ resourceType }, () => this.trigger());
  }

  render() {
    let { resourceType } = this.state;
    let { selectedMerchant, merchants, fulfillers, authToken } = this.props;

    return (
      <div>
        {merchants.length && fulfillers.length ? (
          <RadioGroup inline name="Tenant type" onChange={this.onSelectType} defaultSelected={resourceType}>
            <Radio value="merchant" label="Merchants" />
            <Radio value="fulfiller" label="Fulfillers" />
          </RadioGroup>
        ) : (
          <h3>{resourceType === 'merchant' ? 'Merchants' : 'Fulfillers'}</h3>
        )}
        <div style={resourceType !== 'merchant' ? { display: 'none' } : {}}>
          <MerchantSelect merchants={merchants} selectedMerchant={selectedMerchant} onChange={this.onChange} />
        </div>
        <div style={resourceType !== 'fulfiller' ? { display: 'none' } : {}}>
          <FulfillerSelect
            accessToken={authToken}
            fulfillers={fulfillers}
            onChange={selectedFulfiller => {
              this.setState({ selectedFulfiller });
              if (resourceType === 'fulfiller') {
                this.onChange(selectedFulfiller);
              }
            }}
          />
        </div>
      </div>
    );
  }
}

export default ManyTenantsSelect;

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import inflection from 'lodash-inflection';

_.mixin(inflection);

const SimpleGroup = ({ tenants, selectedTenant, resourceType, label, onSelectTenant }) => (
  <div>
    <h4>
      {_(label)
        .pluralize(tenants.length)
        .toString()}
    </h4>
    <div className="list-group">
      {tenants.map(t => (
        /* TODO: tenant logo */
        /* eslint-disable jsx-a11y/no-static-element-interactions */
        <a
          key={t.value}
          className={`clickable list-group-item ${t.value === selectedTenant ? 'active' : ''}`}
          onClick={() => onSelectTenant({ value: t.value, label: t.label, resourceType })}>
          {/* eslint-enable jsx-a11y/no-static-element-interactions */}
          {t.label}
        </a>
      ))}
    </div>
  </div>
);

SimpleGroup.propTypes = {
  tenants: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedTenant: PropTypes.string,
  resourceType: PropTypes.string.isRequired,
  onSelectTenant: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

SimpleGroup.defaultProps = {
  selectedTenant: undefined,
};

class SimpleSelect extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  static propTypes = {
    selectedMerchant: PropTypes.string,
    selectedFulfiller: PropTypes.string,
    resourceType: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    merchants: PropTypes.arrayOf(
      PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        merchantId: PropTypes.string.isRequired,
      })
    ).isRequired,
    fulfillers: PropTypes.arrayOf(
      PropTypes.shape({
        fulfillerId: PropTypes.string.isRequired,
        internalFulfillerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        archived: PropTypes.bool,
      })
    ).isRequired,
  };

  onChange(option) {
    let { merchants, fulfillers } = this.props;

    let tenantGroups = { merchant: merchants, fulfiller: fulfillers };
    let tenantPrimaryKeys = { merchant: 'merchantId', fulfiller: 'internalFulfillerId' };

    let tenant = tenantGroups[option.resourceType].find(
      t => t[tenantPrimaryKeys[option.resourceType]] === option.value
    );

    if (this.props.onChange) {
      this.props.onChange({
        type: option.resourceType,
        tenant,
      });
    }
  }

  buildMerchantOptions(merchants) {
    return merchants.map(merchant => ({
      label: merchant.displayName,
      value: merchant.merchantId,
    }));
  }

  buildFulfillerOptions(fulfillers) {
    return fulfillers.map(fulfiller => ({
      label: fulfiller.name,
      value: fulfiller.internalFulfillerId,
    }));
  }

  render() {
    let { merchants, fulfillers, selectedMerchant, selectedFulfiller, resourceType } = this.props;

    let merchantOptions = this.buildMerchantOptions(merchants);
    let fulfillerOptions = this.buildFulfillerOptions(fulfillers);

    return (
      <div>
        {merchantOptions.length ? (
          <SimpleGroup
            tenants={merchantOptions}
            selectedTenant={resourceType === 'merchant' ? selectedMerchant : null}
            onSelectTenant={this.onChange}
            resourceType="merchant"
            label="Merchant"
          />
        ) : null}
        {fulfillerOptions.length ? (
          <SimpleGroup
            tenants={fulfillerOptions}
            selectedTenant={resourceType === 'fulfiller' ? selectedFulfiller : null}
            onSelectTenant={this.onChange}
            resourceType="fulfiller"
            label="Fulfiller"
          />
        ) : null}
      </div>
    );
  }
}

export default SimpleSelect;

const BRANDING_SERVICE_API_URL = 'https://api.branding.cimpress.io/v0/brands';

export const fetchBrandingInfo = accessToken => {
  return fetch(BRANDING_SERVICE_API_URL, {
    method: 'GET',
    mode: 'cors',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    }),
  })
    .then(response => {
      if (response.status === 200) {
        return response.json().then(results => {
          if (results.total < 1) {
            // we must get at least 1 brand
            return null;
          }
          return results._embedded.item[0];
        });
      } else {
        return null;
      }
    })
    .catch(error => {
      console.error(error);
      return null;
    });
};

import React from 'react';
import PropTypes from 'prop-types';
import SelectWrapper from '@cimpress/react-components/lib/SelectWrapper';
import VirtualizedSelect from 'react-virtualized-select';
import createFilterOptions from 'react-select-fast-filter-options';

export default class MerchantSelect extends React.Component {
  static propTypes = {
    selectedMerchant: PropTypes.string,
    merchants: PropTypes.arrayOf(
      PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        merchantId: PropTypes.string.isRequired,
      })
    ).isRequired,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onChange: () => {},
  };

  constructor(props) {
    super(props);

    const { merchants, selectedMerchant } = props;
    const options = merchants.map(({ displayName, merchantId }) => ({
      label: displayName,
      value: merchantId,
    }));
    const filterOptions = createFilterOptions({ options, indexes: ['label', 'value'] });

    this.state = {
      selectedMerchantId: selectedMerchant,
      options,
      filterOptions,
    };
  }

  onChange = selectedMerchantId => {
    this.setState({ selectedMerchantId });
    this.props.onChange(this.props.merchants.find(merchant => merchant.merchantId === selectedMerchantId));
  };

  render() {
    const { selectedMerchantId, options, filterOptions } = this.state;
    return (
      <SelectWrapper
        placeholder="Select a merchant"
        clearable={false}
        selectedSelect={VirtualizedSelect}
        value={selectedMerchantId}
        onChange={this.onChange}
        options={options}
        filterOptions={filterOptions}
        simpleValue
      />
    );
  }
}
